<template>
  <div>
    <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
    
    <n-layout has-sider>
      <n-layout>
        <n-layout-header style="background-color:#FFFFFF;height:70px;line-height:70px">LzYu: Welcome!</n-layout-header>
        <n-layout-content
          content-style="padding: 24px;min-height:calc(100vh - 140px);background-color:#F5F7F9"
        >
          <router-view></router-view>
        </n-layout-content>
        <n-layout-footer style="background-color:#FFFFFF;height:70px;line-height:70px"><a style="color:#1E1E1E" href="https://beian.miit.gov.cn">桂ICP备2021006674号-1</a></n-layout-footer>
      </n-layout>
      <n-layout-sider content-style="padding: 24px;background-color:#34373C">
         <el-menu
         style="border:none"
         background-color="#34373C"
         text-color="#ffffff"
            default-active="2"
            class="el-menu-vertical-demo"
          >
          <el-menu-item @click="goRoute(item.route)" v-for="(item,index) in menuItems" :key="index">
              <i :class="item.icon"></i>
              <template #title >{{item.title}}</template>
          </el-menu-item>
          </el-menu>
      </n-layout-sider>
    </n-layout>
  </div>
</template>

<script>

export default {
  methods:{
    goRoute(route){
      this.$router.push(route);
    }
  },
  data() {
    return {
      menuItems : [
        {
          icon : "el-icon-s-home",
          title : "主页",
          route : "/home",
        },
        {
          icon : "el-icon-setting",
          title : "工具",
           route : "/tools",
        },
        {
          icon : "el-icon-folder",
          title : "文件",
           route : "/files",
        },
        {
          icon : "el-icon-monitor",
          title : "后台",
           route : "/admin",
        },
      ]
    };
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
